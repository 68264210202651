<template>
  <div></div>
</template>

<script>
import { signInWithEmailLink } from "@/api/authApi";
export default {
  created() {
    const email = localStorage.getItem("emailLoginUserEmail");
    console.log("in magic link created", email);
    if (!email) {
      this.$router.push("/");
      return;
    }

    this.signIn(email);
  },
  methods: {
    async signIn(email) {
      try {
        await signInWithEmailLink(email);
        localStorage.removeItem("emailLoginUserEmail");
        this.$router.push("/dashboard");
      } catch (error) {
        console.error(error);
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>